<template>
  <div class="d-flex flex-column align-items-end w-100">
    <b-table
      ref="selectableTable"
      selectable
      select-mode="single"
      :items="items"
      :fields="fields"
      :per-page="5"
      :current-page="currentPage"
      responsive
      @row-selected="onRowSelected"
    >
      <!-- Example scoped slot for select state illustrative purposes -->
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <feather-icon icon="EyeOffIcon" />
        </template>

        <template v-else>
          <feather-icon icon="EyeIcon" />
        </template>
      </template>

      <template #cell(avatar)="data">
        <b-avatar :src="data.value" />
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="5"
      first-number
      last-number
      class="mb-0 mt-1 mt-sm-0"
      prev-class="prev-item"
      next-class="next-item"
    >
      <template #prev-text>
        <feather-icon icon="ChevronLeftIcon" size="18" />
      </template>
      <template #next-text>
        <feather-icon icon="ChevronRightIcon" size="18" />
      </template>
    </b-pagination>
  </div>
</template>

<script>
import { BTable, BAvatar, BPagination } from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { getUserData } from "@/auth/utils";
import dayjs from "dayjs";
import tr from "dayjs/locale/tr";
import az from "dayjs/locale/az";
import ru from "dayjs/locale/ru";
export default {
  components: {
    BTable,

    BAvatar,
    FeatherIcon,
    BPagination,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // selectedCheck: false,
      currentPage: 1,
      fields: [
        { key: "selected", label: "" },
        { key: "rationname", label: this.$t("rationName") },
        {
          key: "rasyonfiyati",
          label: this.$t("price"),
          formatter: (val) =>
            parseFloat(val).toFixed(getUserData().pricedecimal) +
            " " +
            getUserData().currency,
          tdClass: "text-right mw-300",
        },
        { key: "kaynak", label: this.$t("source") },
        {
          key: "tarih",
          label: this.$t("date"),
          formatter: (val) =>
            `${dayjs(val)
              .locale(`${this.$i18n.locale}`)
              .format("DD MMMM YYYY HH:mm:ss")}`,
        },
      ],

      selected: [],
    };
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    onRowSelected(items) {
      this.selected = items[0];
      this.$emit("selected", items[0]);
    },
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
.b-table-row-selected {
  background-color: rgb(50, 49, 49) !important;
  td {
    color: black !important;
    transition: 250ms all ease-in-out;
  }
}
</style>
