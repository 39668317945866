var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{},[_c('v-select',{attrs:{"options":_vm.formulas.map(function (x) { return ({
                  label: x.rationname,
                  value: x.rasyonid,
                }); }),"placeholder":"Formula","reduce":function (val) { return val.value; }},on:{"input":_vm.getRevs},model:{value:(_vm.selectedFormula),callback:function ($$v) {_vm.selectedFormula=$$v},expression:"selectedFormula"}})],1)]),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"cols":"12","md":"6"}},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modalPrint",modifiers:{"modalPrint":true}}],staticClass:"mr-2 ml-auto",attrs:{"variant":"outline-primary","size":"sm","disabled":_vm.details.hammaddeler.length === 0 ||
              _vm.details.besinmaddeleri.length === 0}},[_c('feather-icon',{attrs:{"icon":"PrinterIcon"}}),_vm._v(" "+_vm._s(_vm.$t("print"))+" ")],1)],1)],1),_c('b-row',{staticClass:"mt-1 p-1"},[_c('archive-table',{attrs:{"items":_vm.revs},on:{"selected":_vm.selected}})],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('hammadde-table',{attrs:{"items":_vm.details.hammaddeler}})],1),_c('b-col',[_c('besin-maddesi-table',{key:_vm.date,attrs:{"items":_vm.details.besinmaddeleri}})],1)],1)],1)]),_c('b-modal',{staticClass:"modal-lg",attrs:{"id":"modalPrint","title":_vm.$t('printOptions'),"centered":"","size":"xl","hide-footer":""}},[_c('print-modal',{attrs:{"formula-data":{
        rasyon: [_vm.revs.filter(function (x) { return x.rasyonid == _vm.selectedFormula; })[0]],
        hammaddeler: _vm.details.hammaddeler,
        besinmaddeleri: _vm.details.besinmaddeleri,
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }