<template>
  <div>
    <b-card no-body
      ><div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->

          <b-col cols="12" md="6">
            <div class="">
              <v-select
                v-model="selectedFormula"
                :options="
                  formulas.map((x) => ({
                    label: x.rationname,
                    value: x.rasyonid,
                  }))
                "
                placeholder="Formula"
                :reduce="(val) => val.value"
                @input="getRevs"
              ></v-select></div
          ></b-col>
          <!-- Search -->
          <b-col cols="12" md="6" class="d-flex justify-content-end">
            <b-button
              v-b-modal.modalPrint
              variant="outline-primary"
              size="sm"
              class="mr-2 ml-auto"
              :disabled="
                details.hammaddeler.length === 0 ||
                details.besinmaddeleri.length === 0
              "
            >
              <feather-icon icon="PrinterIcon" />
              {{ $t("print") }}
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-1 p-1">
          <archive-table :items="revs" @selected="selected"></archive-table>
        </b-row>

        <b-row class="mt-1">
          <b-col>
            <hammadde-table :items="details.hammaddeler"></hammadde-table>
          </b-col>
          <b-col>
            <besin-maddesi-table
              :key="date"
              :items="details.besinmaddeleri"
            ></besin-maddesi-table>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="modalPrint"
      :title="$t('printOptions')"
      centered
      size="xl"
      hide-footer
      class="modal-lg"
    >
      <print-modal
        :formula-data="{
          rasyon: [revs.filter((x) => x.rasyonid == selectedFormula)[0]],
          hammaddeler: details.hammaddeler,
          besinmaddeleri: details.besinmaddeleri,
        }"
      ></print-modal>
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { BCard, BRow, BCol, BButton } from "bootstrap-vue";
import ArchiveTable from "./Components/ArchiveTable.vue";
import BesinMaddesiTable from "./Components/BesinMaddesiTable.vue";
import HammaddeTable from "./Components/HammaddeTable.vue";
import VueI18n from "@/libs/i18n";
import PrintModal from "./Components/ProductionArchivePrintModal.vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    vSelect,
    ArchiveTable,
    BesinMaddesiTable,
    HammaddeTable,
    BButton,
    PrintModal,
  },
  beforeRouteEnter(to, from, next) {
    if (to.name == "productionFormulasArchive") {
      to.meta.breadcrumb = [];
      to.meta.breadcrumb.push({
        to: "/plants",
        text: VueI18n.t("formulas"),
        active: true,
      });

      to.meta.breadcrumb.push({
        text: "productionFormulasArchive",
        active: true,
      });
    }
    next();
  },
  data() {
    return {
      formulas: [],
      selectedFormula: null,
      revs: [],
      details: { hammaddeler: [], besinmaddeleri: [] },
      date: "",
    };
  },
  mounted() {
    this.getFormulas();
  },
  methods: {
    selected(val) {
      if (val) this.getDetail({ id: val.rasyonid, date: val.tarih });
    },
    async getDetail({ id, date }) {
      this.date = date;
      const { data } = await this.$http.get(
        `/Production/GetProductionArsivRation/${id}`,
        {
          headers: {
            plantid: this.$store.state.app.selectedPlantId,
            Tarih: date,
          },
        }
      );
      this.details = data.resultdata[0];
    },
    async getFormulas() {
      const { data } = await this.$http.get(
        "/Production/GetProductionArsivRations",
        {
          headers: {
            plantId: this.$store.state.app.selectedPlantId,
          },
        }
      );
      this.formulas = data.resultdata;
      this.selectedFormula = this.formulas[0].rasyonid;
      this.getRevs(this.selectedFormula);
    },
    async getRevs(formulaId) {
      const { data } = await this.$http.get(
        "/Production/GetProductionArsivRationsRevs/" + formulaId,
        {
          headers: {
            plantId: this.$store.state.app.selectedPlantId,
          },
        }
      );
      this.details = { hammaddeler: [], besinmaddeleri: [] };
      this.revs = data.resultdata;
    },
  },
};
</script>

<style></style>
